import React, { useState, useEffect } from "react";
import Media from "react-media";
//import hero_video from "./media/videos/hero_video.mp4";
import hero_video from "./media/videos/hero_3_computer.mp4";

import "./App.css";
import "./Technologies.css";

import asistobe_img from "./media/images/asistobe.png";
import mit_img from "./media/images/mit_urban.png";
import locals_covid from "./media/images/locals_covid.png";
import farmcode from "./media/images/farmcode.png";

function Technologies() {
  const [blockOnHover, setBlockOnHover] = useState(null);
  useEffect(() => {
    window.addEventListener("scroll", (e) => console.log("ai ai ai " + e));
    // Update the document title using the browser API
    //document.title = `You clicked ${window.scrollY} times`;
  });
  return (
    <div className="Technologies">
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(screenSize) => <div>{renderTechnologies(screenSize)}</div>}
      </Media>
    </div>
  );

  function renderTechnologies(screenSize) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundColor: "#000c",
          overflow: "hidden",
        }}
      >
        <video
          src={hero_video}
          autoPlay
          loop
          muted
          style={{
            display: "fixed",
            objectFit: "cover",
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            zIndex: "-1000",
          }}
        ></video>
        <div
          style={{
            top: "0px",
            left: "0px",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            zIndex: "10",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: screenSize.small ? "column" : "row",
              //backgroundColor: "red",
              //fontSize: "calc(5vw)",
            }}
          >
            {RenderMainLeftBlock(screenSize)}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                width: screenSize.small ? "100%" : "60vw",
                height: "100vh",
                //backgroundColor: "red",
                scrollSnapType: "y mandatory",
              }}
            >
              <div style={{ height: "auto" }}>
                {/* {renderProjectBlock(
                  "Automate and speed up your business using the last government APIs, and ROBOTs that can finish tasks at the speed of... computers ",
                  "Finance & Accounting",
                  "https://source.unsplash.com/E7RLgUjjazc"
                )} */}
                {renderProjectBlock({
                  description:
                    "Software that helps cities to optimize their public transport service.",
                  title: "Asistobe",
                  imageUrl: asistobe_img,
                  showBackground: true,
                  location: "Bergen, Norway",
                })}
                {renderProjectBlock({
                  description:
                    "Online platform that helps calculate the carbon footprint of your daily food consumption.",
                  title: "MIT - Massachusetts Institute of Technology",
                  imageUrl: mit_img,
                  showBackground: true,
                  location: "Boston, USA",
                })}
                {renderProjectBlock({
                  description:
                    "Mobile App + Internal software that helps the company manage everyday work.",
                  title: "Finpartner",
                  imageUrl:
                    "https://finpartner.pt/wp-content/uploads/2023/08/grafismos-website-2023-07-06-410x230.png",
                  showBackground: true,
                  location: "Lisbon, Portugal",
                })}
                {renderProjectBlock({
                  description: `BIP ZIP local projects classification and search tool.    
                  Covid19 Resource Management online platform.`,
                  title: "Locals - Lisbon Municipality",
                  imageUrl: locals_covid,
                  showBackground: true,
                  location: "Lisbon, Portugal",
                })}
                {renderProjectBlock({
                  description:
                    "Agriculture Simulator webapp + Autonomous Plant Nursery",
                  title: "Farmcode",
                  imageUrl: farmcode,
                  showBackground: true,
                  location: "Lisbon, Portugal",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderProjectBlock({
    description,
    title,
    imageUrl,
    showBackground,
    location,
  }) {
    return (
      <div
        className="technologyBlock moveImageOnHover"
        onMouseEnter={() => setBlockOnHover(title)}
        onMouseLeave={() => setBlockOnHover(null)}
        style={{
          width: "100%",
          height: "calc(60vh)",
          scrollSnapAlign: "center",
          backgroundColor: "#FF7F505f",
          opacity: blockOnHover === title ? 0.8 : 0.5,

          backgroundImage: showBackground ? "url(" + imageUrl + ")" : null,
          //backgroundPosition: "center",
          backgroundPosition: blockOnHover === title ? "30% 70%" : "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: blockOnHover === title ? "grayscale(20%)" : "grayscale(100%)",
        }}
      >
        <div
          className="technologyBlock"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            width: "100%",
            height: "100%",
            backgroundColor: blockOnHover === title ? "#0004" : "#0009",
            boxSizing: "border-box",
            padding: 40,
          }}
        >
          <div
            // className={"moveOnHover"}
            className="doThingsSlowly"
            style={{
              color: "#fff",
              fontSize: 40,
              fontFamily: "Ubuntu",
              backgroundColor: "#0009",
              padding: blockOnHover === title ? 3 : 0,
            }}
          >
            {title}
          </div>
          <div
            // className={blockOnHover === title ? "moveOnHover" : null}
            style={{
              marginTop: "30px",
              color: "#fff",
              backgroundColor: "#0009",
              fontSize: 14,
              fontWeight: 600,
              fontFamily: "Ubuntu",
              maxWidth: "400px",
              lineHeight: 1.6,
            }}
          >
            {description}
          </div>
          <div
            // className={blockOnHover === title ? "moveOnHover" : null}
            style={{
              marginTop: "30px",
              color: "#fff",
              backgroundColor: "#0009",
              fontSize: 14,
              fontWeight: 600,
              fontFamily: "Ubuntu",
              maxWidth: "400px",
              lineHeight: 1.6,
            }}
          >
            {location}
          </div>
        </div>
      </div>
    );
  }

  function RenderMainLeftBlock(screenSize) {
    return (
      <div
        style={{
          width: screenSize.small ? "100%" : "40vw",
          height: "100vh",
          backgroundColor: "#3338",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: 40,
            // backgroundColor: "#0009",
          }}
        >
          <div style={{ fontSize: 50, fontFamily: "Ubuntu", color: "#fff" }}>
            Ongoing<span style={{ color: "#FF7F50" }}> Partners.</span>
          </div>
        </div>

        <div
          style={{
            fontSize: 20,
            fontFamily: "Ubuntu",
            color: "#fff",
            width: "70%",
            marginTop: "100px",
          }}
        >
          Achieving state-of-the-art together<div> Join us.</div>
        </div>
      </div>
    );
  }
}
// const handleNavigation = (e) => {
//   const window = e.currentTarget;

//   if (this.prev > window.scrollY) {
//     console.log("scrolling up");
//   } else if (this.prev < window.scrollY) {
//     console.log("scrolling down");
//   }
//   this.prev = window.scrollY;
// };
export default Technologies;
