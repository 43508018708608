import React, { useState } from "react";

import hero_video from "./media/videos/hero_3_computer.mp4";
import Media from "react-media";
import { slide as Menu } from "react-burger-menu";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import mit_logo from "./media/images/mit_white.png";
import cm_lisboa_logo from "./media/images/logo_lisboa.png";
import c_guerreiro_logo from "./media/images/cg_logo.jpg";

import Tilt from "react-tilt";

import MapChart from "./components/LandingPageMap";

import "./App.css";

function Contact() {
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  return (
    <Media
      queries={{
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)",
      }}
    >
      {(matches) => <div>{renderHero(matches)}</div>}
    </Media>
  );

  function renderHero(matches) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          overflow: "hidden",
        }}
      >
        <video
          src={hero_video}
          autoPlay
          loop
          muted
          style={{
            display: "fixed",
            objectFit: "cover",
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            zIndex: "-1000",
          }}
        ></video>

        <div
          style={{
            top: "0px",
            left: "0px",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            zIndex: "10",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "row",
              flexWrap: "wrap",
              //fontSize: "calc(5vw)",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                fontSize: "60px",
                marginTop: "150px",
                marginLeft: "calc(11vw)",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  display: "flex",
                  color: "white",
                  fontFamily: "Ubuntu",
                }}
              >
                Let's&nbsp;
                {/* <span style={{ textDecoration: "underline" }}> develop</span> */}
              </div>
              <div
                style={{
                  display: "flex",
                  color: "#FF7F50",
                  fontFamily: "Ubuntu",
                }}
              >
                talk
              </div>
              <div
                style={{
                  display: "flex",
                  color: "#FF7F50",
                  fontFamily: "Ubuntu",

                  fontSize: matches.small ? 20 : "30px",
                }}
              >
                hello@perfectorange.pt
              </div>

              <div
                onClick={() =>
                  (window.location = "mailto:hello@perfectorange.pt")
                }
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 45,
                  width: 130,
                  backgroundColor: "#FF7F50",
                  fontFamily: "Oxygen",
                  fontSize: 14,
                  fontWeight: 600,
                  marginTop: 80,
                }}
              >
                Send email
              </div>

              <div
                style={{
                  display: "flex",
                  fontSize: 18,
                  color: "#fff",
                  fontFamily: "Oxygen",
                  fontWeight: 600,

                  marginTop: 90,
                  marginBottom: 10,

                  width: "100%",
                }}
              >
                Or call us at
                {showPhoneNumber ? " +351 96" + (465 + 1) + "9477" : "..."}
                {/* no robots!! */}
              </div>
              <div
                onClick={() => setShowPhoneNumber(true)}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 35,
                  width: 130,
                  backgroundColor: "#FF7F50",
                  fontFamily: "Oxygen",
                  fontSize: 12,
                  fontWeight: 600,
                  padding: 3,
                }}
              >
                Show phone number{" "}
                {/* <span style={{ fontSize: 10 }}>no robots :) </span> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                position: "absolute",
                width: matches.small ? "100%" : "630px",
                height: "500px",
                zIndex: "1",
                bottom: "0",
                right: "80px",
                padding: 20,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  zIndex: 0,
                  //  backgroundColor: "#0009",
                }}
              >
                <Tilt
                  className="Tilt"
                  options={{
                    max: 10,
                    scale: 1,
                    easing: "cubic-bezier(.03,.98,.52,.99)",
                    perspective: 500,
                  }}
                  style={{ height: "100%", width: "100%" }}
                >
                  <MapChart />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function renderHamburgerMenu() {
  return <Menu></Menu>;
}

function renderCustomer(logo) {
  return (
    <div
      className={"floating"}
      style={{
        // backgroundColor: "red",
        backgroundImage: "url(" + logo + ")",
        // backgroundColor: "#fff3",
        borderRadius: "20px",
        //backgroundPosition: "center",
        backgroundPosition: "50% 50%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: "200px",
        height: "100px",
        margin: "" + Math.random() * 40 + 10 + "px",
        animationDelay: "" + Math.random() * 3 + 1 + "s",
      }}
    ></div>
  );
}

export default Contact;
