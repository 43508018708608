import React, { useState, useEffect } from "react";

//import hero_video from "./media/videos/hero_video.mp4";
import Media from "react-media";
import { slide as Menu } from "react-burger-menu";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import "./App.css";
import LandingPage from "./LandingPage";
import Technologies from "./Technologies";
import Contact from "./Contact";
import Projects from "./Projects";

import ScaleLoader from "react-spinners/ScaleLoader";

function App() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    setIsProcessing(true);
  }, isProcessing);
  return (
    <div className="App">
      <div id="outer-container">
        <Router>
          {/* {isProcessing ? renderProcessingScreen() : null} */}

          {renderHamburguerMenu()}

          <main id="page-wrap">
            {isProcessing ? renderProcessingScreen() : null}
            {renderMenuBar()}
            {renderFixedPO()}
            <Switch>
              <Route exact path="/">
                <LandingPage setIsProcessing={setIsProcessing} />
              </Route>
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/technologies" component={Technologies} />
              <Route exact path="/projects" component={Projects} />
            </Switch>
          </main>
        </Router>
      </div>
    </div>
  );
  function handleStateChange(state) {
    //   this.setState({ menuOpen: state.isOpen });
    console.log("WTF", state);
    setMenuOpen(state.isOpen);
  }
  function renderFixedPO() {
    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: 250,
          height: 50,
          fontFamily: "Roboto",
          fontSize: 30,
          color: "#fff4",
          zIndex: 10000,
          //backgroundColor: "red",
        }}
      >
        {" "}
        PerfectOrange©
      </div>
    );
  }
  function closeMenu() {
    //console.log("done");
    setMenuOpen(false);
  }
  function renderProcessingScreen() {
    setTimeout(() => setIsProcessing(false), 2000);

    return (
      <div
        className={"processing"}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "#222",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ScaleLoader
            //css={override}
            size={150}
            color={"#FF7F50"}
            loading={true}
          />
        </div>
      </div>
    );
  }
  function renderHamburguerMenu() {
    return (
      <Menu
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
        styles={menuStyles}
        isOpen={menuOpen}
        onStateChange={(state) => handleStateChange(state)}
      >
        {/* <a id="home" className="menu-item" href="/">
       
      </a> */}
        <Link
          to={"/"}
          style={{
            color: "inherit",
            textDecoration: "inherit",
            marginTop: "50px",
          }}
          onClick={() => {
            setIsProcessing(true);
            setMenuOpen(false);
          }}
        >
          <div style={{ fontSize: 50, fontFamily: "Ubuntu", color: "#fff" }}>
            H<span style={{ color: "#FF7F50" }}>ome</span>
          </div>
        </Link>
        <Link
          onClick={() => {
            setIsProcessing(true);
            closeMenu();
          }}
          to={"/technologies"}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <div style={{ fontSize: 50, fontFamily: "Ubuntu", color: "#fff" }}>
            S<span style={{ color: "#FF7F50" }}>ervices</span>
          </div>
        </Link>
        <Link
          onClick={() => {
            setIsProcessing(true);
            closeMenu();
          }}
          to={"/contact"}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <div style={{ fontSize: 50, fontFamily: "Ubuntu", color: "#fff" }}>
            C<span style={{ color: "#FF7F50" }}>ontact</span>
          </div>
        </Link>
        <Link
          onClick={() => {
            setIsProcessing(true);
            closeMenu();
          }}
          to={"/projects"}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <div style={{ fontSize: 50, fontFamily: "Ubuntu", color: "#fff" }}>
            P<span style={{ color: "#FF7F50" }}>rojects</span>
          </div>
        </Link>
        {/* <a id="about" className="menu-item" href="/about">
        About
      </a>
      <a id="contact" className="menu-item" href="/contact">
        Contact
      </a>
      <a
        onClick={() => console.log("menu clicked")}
        className="menu-item--small"
        href=""
      >
        Settings
      </a> */}
      </Menu>
    );
  }
}
var menuStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "36px",
    top: "45px",
    zIndex: 2000,
  },
  bmBurgerBars: {
    background: "#bdc3c7",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "rgba(0, 0, 0, 0.8)",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(255, 255, 255, 0.1)",
  },
};

function renderMenuBar() {
  return (
    <div
      style={{
        height: "100vh",
        width: "20px",
        display: "flex",
        position: "fixed",
        left: 0,
        top: 0,
        backgroundColor: "#FF7F50",
        zIndex: 1000,
      }}
    ></div>
  );
}
export default App;
