import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import geoUrl from "./worldMap.json";
const markers = [
  {
    //markerOffset: -30,
    name: "Portugal - Base",
    coordinates: [-9.1365919, 38.7077507],
  },
  {
    //markerOffset: -30,
    name: "Asistobe - Bergen Norway",
    coordinates: [5.426506, 60.145849],
  },
  {
    //markerOffset: -30,
    name: "UK",
    coordinates: [-0.1276474, 51.5073219],
  },
  {
    //markerOffset: -30,
    name: "Caiado Guerreiro - China",
    coordinates: [120.2888428, 30.2590226],
  },
  {
    //markerOffset: -30,
    name: "MIT",
    coordinates: [-71.0956779, 42.3583961],
  },
  {
    //markerOffset: -30,
    name: "ISPD - Oman",
    coordinates: [57.0036901, 21.0000287],
  },
  // {
  //   // markerOffset: 15,
  //   name: "Be-Haus",
  //   coordinates: [-43.2093727, -22.9110137],
  // },
  //{ markerOffset: 15, name: "Brasilia", coordinates: [-47.8825, -15.7942] },
];

const MapChart = () => {
  return (
    <ComposableMap
      // projection="geoAzimuthalEqualArea"
      projectionConfig={
        {
          //rotate: [58, 20, 0],
          //  scale: 100,
        }
      }
    >
      {/* <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies
            .filter((d) => d.properties.REGION_UN === "World")
            .map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#EAEAEC"
                stroke="#D6D6DA"
              />
            ))
        }
      </Geographies> */}
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#EAEAEC11"
              stroke="#D6D6DA66"
            />
          ))
        }
      </Geographies>
      {markers.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <g
            fill="none"
            stroke="#FF5533"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "Roboto", fontWeight: 700, fill: "#fff" }}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default MapChart;
