import React from "react";

//import hero_video from "./media/videos/hero_video.mp4";
import hero_video from "./media/videos/hero_3_computer.mp4";

import Media from "react-media";
import { slide as Menu } from "react-burger-menu";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import mit_logo from "./media/images/mit_white.png";
import cm_lisboa_logo from "./media/images/logo_lisboa.png";
import c_guerreiro_logo from "./media/images/cg_logo.jpg";

import Tilt from "react-tilt";

import MapChart from "./components/LandingPageMap";

import "./App.css";
import "./LandingPage.css";

const LandingPage = (props) => {
  return (
    <Media
      queries={{
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)",
      }}
    >
      {(matches) => <div>{renderHero(matches, props)}</div>}
    </Media>
  );
};

// function renderHamburgerMenu() {
//   return <Menu></Menu>;
// }
function renderHero(matches, props) {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        overflow: "hidden",
      }}
    >
      <video
        src={hero_video}
        autoPlay
        loop
        muted
        style={{
          display: "fixed",
          objectFit: "cover",
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
          zIndex: "-1000",
        }}
      ></video>
      <div
        style={{
          top: "0px",
          left: "0px",
          height: "100vh",
          width: "100vw",
          position: "absolute",
          zIndex: "10",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "hidden",
            //fontSize: "calc(5vw)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              fontSize: matches.small || matches.medium ? 40 : "5vw",
              marginTop: matches.small ? "80px" : "150px",
              marginLeft: matches.small ? "20%" : "calc(11vw)",
              zIndex: 1000,
              height: "auto",
              maxHeight: 550,
              overflow: "hidden",
              padding: 20,
            }}
          >
            <Tilt
              className="Tilt"
              options={{
                max: 10,
                scale: 1,
                easing: "cubic-bezier(.03,.98,.52,.99)",
                perspective: 500,
              }}
              style={{ height: "100%", width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  color: "white",
                  fontFamily: "Ubuntu",
                  maxWidth: "80vw",
                  maxHeight: "80vh",
                }}
              >
                We&nbsp;
                <span
                  style={{ textDecoration: "underline", overflowX: "scroll" }}
                >
                  {" "}
                  develop
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  color: "#FF7F50",
                  fontFamily: "Ubuntu",
                  maxWidth: "80%",
                }}
              >
                your future
              </div>
            </Tilt>

            <div
              style={{
                display: "flex",
                fontSize: 18,
                color: "#fff",
                fontFamily: "Oxygen",
                fontWeight: 600,
                marginTop: 50,
                width: "70%",
              }}
            >
              7 years creating high end software for business all around the
              globe.
            </div>
            <Tilt
              className="Tilt"
              options={{
                max: 30,
                scale: 1,
                easing: "cubic-bezier(.03,.98,.52,.99)",
                perspective: 1000,
              }}
              style={{ height: "300px", width: "300px" }}
            >
              <Link
                to={"/technologies"}
                style={{ color: "inherit", textDecoration: "inherit" }}
                onClick={() => props.setIsProcessing(true)}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 45,
                    width: 130,
                    backgroundColor: "#FF7F50",
                    fontFamily: "Oxygen",
                    fontSize: 14,
                    fontWeight: 600,
                    marginTop: 80,
                  }}
                >
                  Tell me more
                </div>
              </Link>
            </Tilt>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              position: "absolute",
              width: matches.small ? "100%" : "500px",
              height: "500px",
              zIndex: "1",
              bottom: "0",
              right: "80px",
              padding: 20,
              maxHeight: "50vh",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                zIndex: 0,
                //  backgroundColor: "#0009",
              }}
            >
              <Tilt
                className="Tilt"
                options={{
                  max: 10,
                  scale: 1,
                  easing: "cubic-bezier(.03,.98,.52,.99)",
                  perspective: 500,
                }}
                style={{ height: "100%", width: "100%", overflow: "hidden" }}
              >
                <MapChart />
              </Tilt>
            </div>

            {/* {renderCustomer(mit_logo)}
            {renderCustomer(cm_lisboa_logo)}
            {renderCustomer(c_guerreiro_logo)}  */}
          </div>
        </div>
      </div>
    </div>
  );
}
function renderCustomer(logo) {
  return (
    <div
      className={"floating"}
      style={{
        // backgroundColor: "red",
        backgroundImage: "url(" + logo + ")",
        // backgroundColor: "#fff3",
        borderRadius: "20px",
        //backgroundPosition: "center",
        backgroundPosition: "50% 50%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: "200px",
        height: "100px",
        margin: "" + Math.random() * 40 + 10 + "px",
        animationDelay: "" + Math.random() * 3 + 1 + "s",
      }}
    ></div>
  );
}

export default LandingPage;
